import api from '@/utils/api';

const initialState = {
    summary: {
        data: null,
        loading: false,
    },
};
const actions = {
    async getTransactionsSummaryAction({ commit, getters }, page = 1) {
        commit('SET_LOADING', true);

        const filters = getters.transactionDataFilters;
        const body = { ...filters, groupId: getters.groupId, page };

        const { data } = await api.post(`/transaction/summary`, body);

        commit('SET_SUMMARY', data);

        commit('SET_LOADING', false);

        return data;
    },
};
const mutations = {
    SET_LOADING(state, value) {
        state.summary.loading = value;
    },
    SET_SUMMARY(state, data) {
        state.summary.data = data;
    },
};
const getters = {
    loagingSummaryGetter(state) {
        return state.summary.loading;
    },
    summaryGetter(state) {
        return state.summary.data ? state.summary.data.data : [];
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters,
};
