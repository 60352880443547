<template lang="pug">
el-select(
    :value="value"
    placeholder="Выберите счет"
    filterable
    :multiple="multiple"
    collapse-tags
    :size="size"
    clearable
    :disabled="disabled"
    @change="change"

)
    el-option-group(
        label="Аккаунты",
        v-if="checkType('accounts')"
    )
        el-option(
            v-for="account in accountsGetter",
            :label="account.name",
            :value="account.id"
            :key="account.id"
        )
            .flex.justify-between.items-center
                div {{ account.name }}
                el-tag(size="mini" type="info") {{ CURRENCY_CHAR_MAP[account.currency] || account.currency }}

    el-option-group(
        label="Мерчанты",
        v-if="checkType('merchants')"
    )
        el-option(
            v-for="merchant in merchants",
            :label="getMerchantLabel(merchant)",
            :value="unique ? merchant : `${merchant.name}--${merchant.currency}`"
            :key="unique ? merchant : `${merchant.name}--${merchant.currency}`"
        )
            .flex.justify-between.items-center
                div {{ getMerchantLabel(merchant) }}
                el-tag(size="mini" type="info" v-if="!unique")
                    | {{ CURRENCY_CHAR_MAP[merchant.currency] || merchant.currency }}
</template>

<script>
import { mapGetters } from 'vuex';

import { CURRENCY_CHAR_MAP } from '@/utils/constants';

export default {
    props: {
        type: {
            type: String,
            default: 'all',
        },
        size: {
            type: String,
            default: 'small',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        unique: {
            type: Boolean,
            default: false,
        },
        value: {},
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            CURRENCY_CHAR_MAP,
            categories: [],
        };
    },
    computed: {
        ...mapGetters(['merchantsGetter']),
        ...mapGetters('accounts', ['accountsGetter']),
        merchants() {
            if (!this.unique) return this.merchantsGetter;
            const unique = new Set();
            this.merchantsGetter.forEach((m) => unique.add(m.name));
            return [...unique];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        change(value) {
            this.$emit('input', value);
            this.$emit('change', value);
        },
        getMerchantLabel(merchant) {
            return this.unique ? merchant : merchant.name;
        },

        checkType(sourceType) {
            return this.type === 'all' || this.type === sourceType;
        },
        async init() {},
    },
};
</script>
