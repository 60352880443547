import Vue from 'vue';
import VueRouter from 'vue-router';

import Container from '@/components/Container';
import Login from '@/components/Login';
import AccessDenied from '@/components/pages/access-denied/AccessDenied';
// import MerchantBalancesPage from '@/components/pages/merchantbalances/MerchantBalancesPage';
import SetUpAuthenticatorPage from '@/components/pages/authenticator/SetUpAuthenticatorPage';
import TransactionListPage from '@/components/pages/operation-list/TransactionListPage';
import SettingsPage from '@/components/pages/settings/SettingsPage';
import routerGuard from '@/router/routerGuard';

// import ChartsPage from '@/components/pages/statistic/ChartsPage';
// import StatisticPage from '@/components/pages/statistic/StatisticPage';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/',
        component: Container,
        redirect: '/operation-list',
        name: 'root',

        children: [
            {
                path: '/operation-list',
                component: TransactionListPage,
                name: 'operations',
                meta: {
                    title: 'Операции',
                    access: ['ACCESS_OPERATIONS'],
                },
            },
            // {
            //     path: '/statistics',
            //     component: StatisticPage,
            //     meta: { title: 'Сводка' },
            // },
            // {
            //     path: '/charts',
            //     component: ChartsPage,
            //     meta: { title: 'Сводка' },
            // },
            // {
            //     path: '/merchant-balances',
            //     component: MerchantBalancesPage,
            //     name: 'balances',
            //     meta: {
            //         title: 'Балансы',
            //         access: ['ACCESS_BALANCES'],
            //     },
            // },
            {
                path: '/settings',
                component: SettingsPage,
                name: 'settings',
                meta: {
                    title: 'Настройки',
                    access: ['ACCESS_SETTINGS'],
                },
            },
            {
                path: '/401',
                name: 'accessDenied',
                component: AccessDenied,
            },
        ],
    },
    {
        path: '/enable-google-authenticator',
        name: 'enableGoogleAuthenticator',
        component: SetUpAuthenticatorPage,
        meta: { title: 'Настройка двух факторной аутентификации' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(routerGuard);

export default router;
