<template lang="pug">
span.copyable(@click="handleClick" v-clipboard:copy="value") {{ value }}
</template>

<script>
import Notify from '@/utils/notify';

export default {
    props: {
        value: {
            type: [Number, String],
            required: true,
        },
    },

    methods: {
        handleClick() {
            Notify.success({
                title: 'Успешно',
                message: 'Скопировано в буфер обмена',
            });
        },
    },
};
</script>
