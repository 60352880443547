<template lang="pug">
el-drawer(:visible.sync="show" direction="rtl" size="40%" @closed="close")
    div(slot="title")
        .title Фильтры
    .drawer-content
        el-form.full(:model="filters")
            .flex.full.mt-2
                el-form-item.full.mr-2
                    template(slot="label")
                        .form-label Счета
                    AccountsSelect.full(v-model="filters.accounts" :type="'accounts'" multiple)
                el-form-item.full
                    template(slot="label")
                        .form-label Мерчант
                    AccountsSelect.full(v-model="filters.merchants" :type="'merchants'" multiple)
            el-form-item.form-item
                template(slot="label")
                    .form-label Категория
                CategoriesCascader(
                    v-model="filters.categoryPath"
                    type="manager"
                    size="small"
                    :filters="{project: 'accountancy'}"
                )
            .flex.full.form-item
                el-form-item.full.mr-2
                    template(slot="label")
                        .form-label Тип даты
                    el-select.full(
                        :size="size"
                        v-model="filters.datePickerFilterType"
                        @change="changeDateTypes"
                    )
                        el-option(
                            v-for="datePickerFilterType in datePickerFilterTypes"
                            :key="datePickerFilterType.value"
                            :label="datePickerFilterType.label"
                            :value="datePickerFilterType.value"
                        )
                el-form-item.full
                    template(slot="label")
                        .form-label Дата операции
                    el-date-picker.full(
                        :size="size"
                        v-model="filters.dateRange"
                        @change="changeDateRange"
                        :type="filters.datePickerFilterType"
                        align="right"
                        unlink-panels=""
                        range-separator="|"
                        placeholder="Выберите дату"
                        start-placeholder="От"
                        end-placeholder="До"
                        :picker-options="datePickerOptions[filters.datePickerFilterType]"
                    )
            el-form-item.form-item
                template(slot="label")
                    .form-label Автор выплаты в бухгалтерии
                el-select.full(
                    placeholder="Выберите автора"
                    v-model="filters.author"
                    clearable
                    :size="size"
                )
                    el-option(
                        :key="0"
                        :value="null"
                        label="Все"
                    )
                    el-option(
                        v-for="option in transactionData.filterAuthors"
                        :key="option.id",
                        :value="option.id"
                        :label="option.name"
                    )
            el-form-item.form-item
                template(slot="label")
                    .form-label Автор выплаты в глобальной админке
                el-select.full(
                    placeholder="Выберите автора"
                    v-model="filters.payoutAuthor"
                    clearable
                    :size="size"
                )
                    el-option(
                        :key="0"
                        :value="null"
                        label="Все"
                    )
                    el-option(
                        v-for="option in transactionData.filterPayoutAuthors"
                        :key="option.id",
                        :value="option.id"
                        :label="option.name"
                    )
            .flex.form-item.full
                el-form-item.full.mr-2
                    template(slot="label")
                        .form-label Сумма выплаты
                    el-input-number.full(
                        placeholder="Введите число"
                        v-model="filters.payoutSumm"
                        :size="size"
                    )
                el-form-item.full
                    template(slot="label")
                        .form-label Тип выплаты
                    el-select.full(
                            placeholder="Выберите тип выплаты"
                            v-model="filters.payoutType"
                            clearable
                            :size="size"
                        )
                            el-option(
                                v-for="type in payoutTypes"
                                :key="type.value"
                                :value="type.value"
                                :label="type.label"
                            )
            .flex.form-item.full
                el-form-item.full.mr-2
                    template(slot="label")
                        .form-label Отредактированные выплаты
                    el-checkbox.full(
                            size="mini"
                            v-model="filters.showOnlyEdited"
                        ) Показывать только отредактированные выплаты

    .actions
        el-button(@click="clear" size="small") Сбросить
        el-button(type="primary" @click="submit" :loading="loading" size="small")
            | Применить

</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import AccountsSelect from '@/components/ui/AccountsSelect';
import CategoriesCascader from '@/components/ui/CategoriesCascader';
import {
    PAYOUT_TYPES,
    LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY,
} from '@/utils/constants';

const initialFilters = () => {
    const localStorageFilters = localStorage.getItem(
        LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY,
    );
    const filters = {
        author: null,
        payoutSumm: null,
        payoutType: null,
        payoutAuthor: null,
        accounts: [],
        merchants: [],
        categoryPath: [],
        datePickerFilterType: 'monthrange',
        showOnlyEdited: false,
        daterange: [],
    };

    return localStorageFilters ? JSON.parse(localStorageFilters) : filters;
};

export default {
    name: 'CategoryDrawer',
    components: { CategoriesCascader, AccountsSelect },
    data: () => ({
        loading: false,
        size: 'small',
        filters: initialFilters(),
        datePickerFilterTypes: [
            { value: 'monthrange', label: 'По месяцам' },
            { value: 'daterange', label: 'По дням' },
        ],
        datePickerOptions: {
            monthrange: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [
                    {
                        text: 'Текущий месяц',
                        onClick: (picker) =>
                            picker.$emit('pick', [
                                moment()
                                    .startOf('month')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                moment()
                                    .endOf('month')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                            ]),
                    },
                    {
                        text: 'Предыдущий месяц',
                        onClick: (picker) => {
                            const monthAgo = moment().subtract({ month: 1 });

                            return picker.$emit('pick', [
                                monthAgo
                                    .startOf('month')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                monthAgo
                                    .endOf('month')
                                    .format('YYYY-MM-DD HH:mm:ss'),
                            ]);
                        },
                    },
                ],
            },
            daterange: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [
                    {
                        text: 'Сегодня',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().format('YYYY-MM-DD HH:mm:ss'),
                                moment().format('YYYY-MM-DD HH:mm:ss'),
                            ]);
                        },
                    },
                    {
                        text: 'Вчера',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().subtract({ day: 1 }),
                                moment().subtract({ day: 1 }),
                            ]);
                        },
                    },
                    {
                        text: 'Неделя',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().subtract({ week: 1 }),
                                moment().format('YYYY-MM-DD HH:mm:ss'),
                            ]);
                        },
                    },
                ],
            },
        },
        show: false,
    }),
    computed: {
        ...mapGetters(['transactionData']),
        payoutTypes: () =>
            Object.entries(PAYOUT_TYPES).map(([value, label]) => ({
                value,
                label,
            })),
    },
    methods: {
        ...mapActions(['loadTransactionData']),
        changeDateTypes(value) {
            if (value === 'monthrange')
                this.changeDateRange(this.filters.dateRange);
        },
        changeDateRange(val) {
            if (!val) return;

            const [firstDate, secondDate] = [val].flat();

            this.filters.dateRange = [
                moment(firstDate)
                    .startOf(
                        this.filters.datePickerFilterType === 'monthrange'
                            ? 'month'
                            : 'day',
                    )
                    .format('YYYY-MM-DD HH:mm:ss'),
                moment(secondDate)
                    .endOf(
                        this.filters.datePickerFilterType === 'monthrange'
                            ? 'month'
                            : 'day',
                    )
                    .format('YYYY-MM-DD HH:mm:ss'),
            ];
        },
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        clear() {
            localStorage.removeItem(LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY);
            this.filters = initialFilters();
            this.$store.commit('setTransactionFilters', this.filters);
            this.loadTransactionData();
            this.$emit('changeFilters', this.filters);
            this.close();
        },
        submit() {
            this.$store.commit('setTransactionFilters', this.filters);
            localStorage.setItem(
                LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY,
                JSON.stringify(this.filters),
            );
            this.loadTransactionData();
            this.$emit('changeFilters', this.filters);
            this.close();
        },
    },
};
</script>

<style scoped lang="scss">
.drawer-content {
    height: calc(100vh - 116px);
    position: relative;
    padding: 20px;
    margin-top: -40px;
    box-sizing: border-box;
    overflow-y: auto;
}

.title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.full {
    width: 100% !important;
}

.actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    background: #f9f9f9;
    padding: 20px;
}

.form-item {
    margin-top: -20px;
}
.form-label {
    margin-bottom: -15px;
    font-weight: bold;
    font-size: 13px;
}

.flex {
    display: flex;
}

.mr-2 {
    margin-right: 20px;
}
</style>
