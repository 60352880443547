<template lang="pug">
.step-four.flex.flex-col.items-center
    el-result.max-w-xs(icon="info" subTitle="Для сохранения настройки необходимо ввести код из приложения")
    PasscodeInput.w-64(:update-code="updateCode" :submit="saveSettings" ref="passcode")
    .text-sm.mt-4.w-60.text-center.opacity-80(v-if="synchronizationTip") {{ synchronizationTip }}

    el-row.w-64.mt-8(:gutter="10")
        el-col(:span="10")
            el-button.w-full(@click="$emit('previousStep')") Назад
        el-col(:span="14")
            el-button.w-full(type="primary" @click="saveSettings" :disabled="isLoading") Подтвердить
</template>

<script>
import { mapActions } from 'vuex';

import { AUTHENTICATOR_SYNCHRONIZATION_TIP } from '@/utils/constants';
import Notify from '@/utils/notify';

import PasscodeInput from '../../ui/PasscodeInput';

export default {
    components: { PasscodeInput },
    data() {
        return {
            authenticationCode: '',
            synchronizationTip: '',
            isLoading: false,
        };
    },
    methods: {
        ...mapActions('authenticator', ['confirm2faAction']),
        async saveSettings() {
            if (this.authenticationCode.length < 6) return;

            try {
                this.isLoading = true;
                await this.confirm2faAction({
                    passcode: this.authenticationCode,
                });

                Notify.success({
                    title: 'Успешно',
                    message: 'Аутентификатор настроен',
                });

                this.$router.push('/');
            } catch (e) {
                if (e.response.status === 403) {
                    this.synchronizationTip = AUTHENTICATOR_SYNCHRONIZATION_TIP;
                    this.$refs.passcode.$el.classList.add('auth-error');
                }
            } finally {
                this.isLoading = false;
            }
        },
        updateCode(value) {
            this.authenticationCode = value;
        },
    },
};
</script>
