<template lang="pug">
el-cascader.w-full(
    :value="value"
    filterable
    :options="categories"
    :props="props"
    collapse-tags
    clearable
    @change="change"
    :disabled="disabled"
    :size="size"
    placeholder="Выберите категорию"
)
</template>

<script>
import { mapActions } from 'vuex';

import { STRUCTURE_TYPES } from '@/utils/constants';

export default {
    props: {
        type: {
            type: String,
            default: 'all',
        },
        value: Array,
        props: {
            type: Object,
            default: () => ({
                multiple: true,
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
            default: () => ({
                type: STRUCTURE_TYPES.DEPARTMENT,
                project: 'staff',
            }),
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data() {
        return {
            categories: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions('relationManager', [
            'getAllStructureAction',
            'getManagerStructureAction',
        ]),

        change(value) {
            this.$emit('input', value);
            this.$emit('change');
        },

        async init() {
            const handler =
                this.type === 'all'
                    ? this.getAllStructureAction
                    : this.getManagerStructureAction;

            const { data } = await handler({
                filters: this.filters,
                options: { changeStore: false },
            });

            this.categories = data;
        },
    },
};
</script>
