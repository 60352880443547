<template lang="pug">
.wrapper(v-loading="categoriesStructureLoadingGetter")
    .filters-wrapper
        el-input.input-filter(placeholder='Введите название категории' v-model="filters.search" size="small")
        el-button(
            size='mini' 
            plain type="success" 
            icon="el-icon-plus" 
            @click="openDrawer('CategoryDrawer')"
        ) Добавить корневую категорию
    el-tree(:data="categoriesStructureGetter"
        ref="categoriesTreeRef" 
        node-key="path"
        :expand-on-click-node="false"
        :filter-node-method="filterCategories"
    )
        .custom-tree-node(slot-scope='{ node, data }')
            div {{ data.label }}
            .actions
                el-button(type="primary" size='mini' plain @click="openDrawer('CategoryDrawer', data)")
                    | Добавить
                el-button(size='mini' plain @click="openDrawer('CategoryDrawer', data, 'edit')")
                    | Редактировать

    CategoryDrawer(ref="CategoryDrawer" @onSubmit="getStructure")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CategoryDrawer from './CategoryDrawer.vue';

export default {
    name: 'TransactionCategoriesSetupPage',
    components: { CategoryDrawer },
    data: () => ({
        filters: { search: '' },
    }),
    computed: {
        ...mapGetters('relationManager', [
            'categoriesStructureGetter',
            'categoriesStructureLoadingGetter',
        ]),
    },
    watch: {
        'filters.search'(val) {
            this.$refs.categoriesTreeRef.filter(val);
        },
    },
    methods: {
        ...mapActions('relationManager', ['getManagerStructureAction']),
        async getStructure() {
            this.getManagerStructureAction({
                filters: { project: 'accountancy' },
            });
        },
        filterCategories(value, data) {
            const name = data.pathName.toLowerCase();
            const search = this.filters.search.toLowerCase();

            return name.includes(search);
        },
        openDrawer(name, category, mode) {
            this.$refs[name].open(category);
            if (mode) {
                this.$refs[name].setMode(mode);
            }
        },
    },
};
</script>

<style lang="scss">
.el-tree-node__content {
    height: 35px !important;
}
</style>

<style scoped lang="scss">
.custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    height: 15px;
}

.actions {
    display: flex;
    align-items: center;
}

.filters-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.input-filter {
    margin-right: 10px;
    &:last-child {
        margin-right: 0 !important;
    }
}
</style>
