<template lang="pug">
.settings-table
    el-table(:data="accountsGetter" v-loading="loadingAccountsGetter")
        el-table-column(label="Имя")
            template(slot-scope="scope")
                span(v-if="editRow.id !== scope.row.id") {{ scope.row.name }}
                el-input(v-else
                    size="mini"
                    v-model="editRow.name"
                    placeholder="Укажите название счета"
                    autocomplete="off")

        el-table-column(label="Валюта" prop="currency")
            template(slot-scope="scope")
                span {{ CURRENCY_CHAR_MAP[scope.row.currency] }}

        el-table-column(label="Operations" width="200px")
            template(slot="header" slot-scope="scope")
                el-button(@click="openModal('AccountsModal')"
                    size="mini"
                    type="success"
                    plain
                    icon="el-icon-plus") Создать новый

            template(slot-scope="scope")
                template(v-if="editRow.id !== scope.row.id")
                    el-button(v-if="!scope.row.source" size="mini" @click="handleEdit(scope.row)") Изменить
                    el-button(
                        v-if="!scope.row.source"
                        size="mini"
                        type="danger"
                        @click="remove(scope.row)"
                    ) Удалить
                template(v-else)
                    el-button(size="mini" @click="resetEditRow") Отмена
                    el-button(
                        size="mini"
                        type="success"
                        :loading="isLoading.saveButton"
                        @click="save(scope.row)"
                    ) Сохранить

    AccountsModal(ref="AccountsModal")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { CATEGORY_TYPE_MAP, CURRENCY_CHAR_MAP } from '@/utils/constants';

import AccountsModal from './modals/AccountsModal';

const emptyEditRow = () => ({
    id: undefined,
    name: '',
});

export default {
    name: 'AccountsTab',
    components: { AccountsModal },
    data: () => ({
        CURRENCY_CHAR_MAP,
        CATEGORY_TYPE_MAP,
        formVisibleStatus: false,
        editRow: emptyEditRow(),
        isLoading: {
            deleteButton: false,
            saveButton: false,
        },
    }),
    computed: {
        ...mapGetters(['groupId']),
        ...mapGetters('accounts', ['accountsGetter', 'loadingAccountsGetter']),
        rowList() {
            return this.rows.filter((row) => {
                if (this.propertyType === 'category')
                    return row.type === this.categoryType;
                return true;
            });
        },
    },
    methods: {
        ...mapActions('accounts', [
            'updateAccountAction',
            'getAccountsAction',
            'removeAccountAction',
        ]),
        openModal(modal) {
            this.$refs[modal].open();
        },
        updateFormVisibleStatus(val = true) {
            this.formVisibleStatus = val;
        },
        handleEdit(row) {
            this.editRow = { ...row };
        },
        resetEditRow() {
            this.editRow = emptyEditRow();
        },
        async remove(account) {
            await this.$confirm(
                `Вы уверены, что хотите удалить счет ${account.name}?`,
                'Внимание',
                {
                    confirmButtonText: 'Продолжить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                },
            );

            await this.removeAccountAction(account.id);
            this.$notify({
                title: 'Успешно',
                message: 'Счет удален',
                type: 'success',
                duration: 2000,
            });
            await this.getAccountsAction({ groupId: this.groupId });
        },
        async save() {
            await this.updateAccountAction({
                id: this.editRow.id,
                name: this.editRow.name,
            });
            this.$notify({
                title: 'Успешно',
                message: 'Счет изменен',
                type: 'success',
                duration: 2000,
            });
            await this.getAccountsAction({ groupId: this.groupId });
            this.resetEditRow();
        },
    },
};
</script>

<style scoped lang="scss">
.table-block {
    .add-link:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        bottom: 0;
        border-bottom: 1px solid teal;
    }

    .add-link:after:hover {
        border-bottom: 0;
    }
}
</style>
