import api from '@/utils/api';

const initialState = {
    accounts: {
        data: null,
        loading: false,
    },
    accountsBalances: {
        loading: false,
        data: null,
    },
};
const actions = {
    async getAccountsAction({ commit }, { groupId }) {
        commit('SET_LOADING', true);
        const { data } = await api.get(`/account/${groupId}`);

        commit('SET_ACCOUNTS', data);

        commit('SET_LOADING', false);

        return data;
    },

    async getAccountsBalancesAction({ commit }, { groupId }) {
        commit('SET_BALANCES_LOADING', true);
        const { data } = await api.get(`/account/${groupId}/balances`);

        commit('SET_BALANCES', data);

        commit('SET_BALANCES_LOADING', false);

        return data;
    },

    async updateAccountAction(_, form) {
        await api.put('/account', form);
    },

    async removeAccountAction(_, accountId) {
        await api.delete(`/account/${accountId}`);
    },

    async createAccountAction(_, form) {
        const { data } = await api.post('/account', form);

        return data;
    },

    async convertBalancesAction(_, { converter, currency }) {
        const accountBalances = getters.accountsBalancesGetter(initialState);
        const toConvert = [];
        const balances = [];

        Object.values(getters.accountsGetter(initialState)).forEach((acc) => {
            const amount = accountBalances[acc?.id]?.balance ?? 0;

            if (
                acc?.currency &&
                acc?.currency !== currency &&
                Math.abs(amount) > 0
            ) {
                toConvert.push({
                    amount,
                    from: acc?.currency,
                    to: currency,
                });
            } else {
                balances.push(amount);
            }
        });

        const converted = await converter(toConvert);

        return [...balances, ...converted];
    },
};
const mutations = {
    SET_LOADING(state, value) {
        state.accounts.loading = value;
    },
    SET_ACCOUNTS(state, data) {
        state.accounts.data = data;
    },
    SET_BALANCES_LOADING(state, value) {
        state.accountsBalances.loading = value;
    },
    SET_BALANCES(state, data) {
        state.accountsBalances.data = data;
    },
};
const getters = {
    loadingAccountsGetter(state) {
        return state.accounts.loading;
    },
    accountsGetter(state) {
        return state.accounts.data
            ? state.accounts.data.data.filter((a) => !a.is_removed)
            : [];
    },
    accountsMapGetter(state) {
        return state.accounts.data
            ? state.accounts.data.data.reduce((acc, account) => {
                  if (!acc[account.id]) acc[account.id] = account;
                  return acc;
              }, {})
            : [];
    },
    loadingAccountsBalancesGetter(state) {
        return state.accountsBalances.loading;
    },
    accountsBalancesGetter(state) {
        return state.accountsBalances.data
            ? state.accountsBalances.data.data
            : {};
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters,
};
