<template lang="pug">
.transaction-table
  el-table(
    :data="transactionData.list"
    v-loading="transactionData.isLoading"
    show-summary
    :summary-method="summaries"
  )
    el-table-column(
      label="Дата"
      prop="source_payout_time"
      ref="dateCol"
    )
      template(v-slot:header="scope")
        | Дата
        span.caret-wrapper
          i.sort-caret.ascending
          i.sort-caret.descending
      template(slot-scope="scope")
        | {{ getTransactionTime(scope.row.source_payout_time) }}

    el-table-column(
      label="Тип"
      prop="type"
    )
      template(slot-scope="scope")
        span(:class="['transaction-type', scope.row.type]") {{ typeLabel(scope.row) }}


    el-table-column(
      label="Категория"
      prop="categoryPathName"
    )
      template(slot-scope="scope")
        el-tooltip.item(
          v-if="scope.row.categoryPathName && scope.row.categoryPathName.length"
          effect="dark"
          placement="top"
        )
          template(slot="content")
            span {{ scope.row.categoryPathName }}
          span {{ getCategoryName(scope.row) }}

    el-table-column(
      label="Сумма"
      prop="amount"
      ref="amountCol"
    )
      template(v-slot:header="scope")
        | Сумма
        span.caret-wrapper
          i.sort-caret.ascending
          i.sort-caret.descending
      template(slot-scope="scope")
        | {{ Math.abs(scope.row.amount) | money(scope.row.currency) }}

        el-tooltip.item(
          effect="dark"
          content="Комиссия за операцию"
          placement="right"
        )
          span.fee(v-if="scope.row.fee !== 0") {{ Math.abs(scope.row.fee) | money(scope.row.currency) }}

    el-table-column(
      label="Счет"
      prop="account_id"
    )
      template(slot-scope="scope")
        el-tooltip.item(
          effect="dark"
          placement="top"
        )
          template(slot="content")
            span {{ getTooltipContent(scope.row) }}
            div(v-if="scope.row.type === 'transfer'")
              span Транзакция:
              b {{ scope.row.parent_id || scope.row.id }}
          span(v-if="scope.row.merchant_name") {{ scope.row.merchant_name || "-" }}
          span(v-else) {{ accountName(scope.row) }}

    el-table-column(
      label="Автор"
      prop="authorName"
    )
      template(slot-scope="scope")
        | {{ scope.row.authorName }}

    el-table-column
        template(slot-scope="scope" v-if="scope.row.editedHistory")
          div
            div Отредактировано: {{ scope.row.editedHistory.name }}
            div {{ scope.row.editedHistory.changed_at | moment }}

    el-table-column(width="120px")
      template(slot-scope="scope")
        div(v-if="scope.row.comment")
          el-popover(
              placement="top-end"
              title="Комментарий"
              trigger="click"
              :content="scope.row.comment"
          )
            el-link.comment-link(
              slot="reference"
              type="info"
            ) Комментарий
        div(v-if="scope.row.imagesIds && scope.row.imagesIds.length")
          el-link.comment-link(@click="openImagesDialog(scope.row)") Изображения


    el-table-column(width="55px")
      template(slot-scope="scope")
        .actions
          el-button.button(
            v-if="showRemoveButton(scope.row)"
            icon="el-icon-delete"
            size="mini"
            @click="removeTransaction(scope.row.id)"
          )
          //- el-upload.button(
          //-   :action="`/api/transaction/${scope.row.id}/image/upload`"
          //-   :headers="uploadHeaders"
          //-   :on-error="onUploadError"
          //-   :on-success="(response) => onUploadSuccess(scope.row, response)"
          //- )
          //-   el-button(
          //-     size="mini"
          //-     icon="el-icon-upload"
          //-   )
          el-button.button(
            v-if="showEditButton(scope.row)"
            @click="showCreateTransactionForm(scope.row)"
            icon="el-icon-edit"
            size="mini"
          )


  el-pagination(
    background
    layout="prev, pager, next"
    :current-page="transactionData.currentPage"
    :total="transactionData.totalCount"
    :page-size="transactionData.pageSize"
    @current-change="changePage"
  )
  el-dialog(
    :visible="imagesDialogVisible"
    title="Загруженные изображения"
    @close="imagesDialogClosed"
  )
    template(v-if="imagesData.isLoading")
      i.el-icon-loading
    template(v-if="imagesData.isLoaded && imagesData.imageUrls.length")
      el-image.transaction-image(
        v-for="(url, i) in imagesData.imageUrls"
        :key="imagesData.imageIds[i]"
        :src="url"
      )
    template(v-else-if="imagesData.isLoaded && !imagesData.imageUrls.length")
      div Для этой транзакции не загружено ни одного изображения

    template(v-else-if="imagesData.isError")
      div Ошибка загрузки изображений

  create-transaction-form(
    @updateVisibleStatus="showCreateTransactionForm(false)"
    :editableRow="editableRow"
    :type="createType"
  )
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import CreateTransactionForm from '@/components/tools/CreateTransactionForm';
import api, { TOKEN_KEY } from '@/utils/api';

const CancelToken = axios.CancelToken;
const imagesDataFactory = () => ({
    transactionId: null,
    imageIds: [],
    imageUrls: [],
    cancelTokenSource: null,
    isLoading: false,
    isLoaded: false,
    isError: false,
});

export default {
    name: 'TransactionTable',
    components: { CreateTransactionForm },
    data() {
        return {
            uploadHeaders: { token: localStorage.getItem(TOKEN_KEY) },
            imagesDialogVisible: false,
            imagesData: imagesDataFactory(),
            editableRow: null,
            createType: null,
        };
    },
    computed: {
        ...mapGetters('accounts', ['accountsMapGetter']),
        ...mapGetters('transactions', ['summaryGetter']),
        ...mapGetters(['transactionData']),
        ...mapGetters(['authUser']),
        accountName() {
            return (row) =>
                this.accountsMapGetter[row.account_id]
                    ? this.accountsMapGetter[row.account_id].name
                    : '-';
        },
    },
    async mounted() {
        await this.loadTransactionData();
        this.getTransactionsSummaryAction();
        this.customListeners = [];
        // Все это ниже нужно, чтобы сортировать сразу по нескольким полям одновременно,
        // потому что стандартными способами так нельзя
        // референс на компонент -> https://element.eleme.io/#/en-US/component/table#table
        Object.keys(this.transactionData.sortOrders).forEach(
            this.addColListener,
        );
        this.toggleSortClasses();
        this.unwatch = this.$watch(
            'transactionData.sortOrders',
            function (o, n) {
                if (o.amount === n.amount || o.date === n.date) return;
                this.toggleSortClasses();
                this.loadTransactionData();
                this.getTransactionsSummaryAction();
            },
            { deep: true },
        );
    },
    beforeDestroy() {
        this.removeListeners();
        this.unwatch?.();
    },
    methods: {
        ...mapActions(['loadTransactionData', 'loadPropertiesData']),
        ...mapActions('transactions', ['getTransactionsSummaryAction']),
        ...mapMutations(['setIsTransactionLoading']),
        getCategoryName(row) {
            return row.categoryPathName && row.categoryPathName.length
                ? row.categoryPathName.split('/').pop()
                : '-';
        },
        showCreateTransactionForm(row) {
            this.createType = row.type;
            this.editableRow = row;
        },
        getTooltipContent(data) {
            const str = data.account_id ? 'Аккаунт' : 'Мерчант';
            return str;
        },
        changePage(page) {
            this.loadTransactionData(page);
            this.getTransactionsSummaryAction(page);
        },
        typeLabel({ type, parent_id: parentId }) {
            if (type === 'income') return 'Доход';
            if (type === 'expense') return 'Расход';
            if (type === 'transfer') {
                if (parentId) return 'Списано';
                return 'Получено';
            }

            return type;
        },
        async removeTransaction(id) {
            await this.$confirm(
                'Вы уверены, что хотите удалить операцию?',
                'Внимание',
                {
                    confirmButtonText: 'Продолжить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                },
            );

            this.setIsTransactionLoading(true);
            try {
                await api.delete(`/transaction/${id}`);

                await Promise.all([
                    this.loadTransactionData(this.transactionData.currentPage),
                    this.getTransactionsSummaryAction(
                        this.transactionData.currentPage,
                    ),
                    this.loadPropertiesData(),
                ]);
            } finally {
                this.setIsTransactionLoading(false);
            }
        },
        summaries() {
            const summaries = this.summaryGetter || {};
            return [
                `Итого за период`,
                `Расход: \n\r${this.$options.filters.money(
                    summaries.expense || 0,
                )}`,
                `Доход: \n\r${this.$options.filters.money(
                    summaries.income || 0,
                )}`,
                `Комиссия: \n\r${this.$options.filters.money(
                    summaries.fee || 0,
                )}`,
                `Переведено: \n\r${this.$options.filters.money(
                    summaries.transferFrom || 0,
                )}`,
                `Получено переводами: \n\r${this.$options.filters.money(
                    summaries.transferTo || 0,
                )}`,
            ];
        },
        onUploadError(err) {
            let message = 'Ошибка загрузки файла';
            switch (err.status) {
                case 413:
                    message = 'Файл слишком большой';
                    break;
                default:
                    break;
            }
            this.$message({
                message,
                type: 'error',
            });
        },
        async openImagesDialog({ id, imagesIds }) {
            this.imagesData.isLoading = true;
            this.imagesData.transactionId = id;
            this.imagesData.imageIds = imagesIds;
            this.imagesDialogVisible = true;
            const CTS = CancelToken.source();
            this.imagesData.cancelTokenSource = CTS;
            try {
                const loadedImages = await Promise.all(
                    imagesIds.map((imageId) =>
                        api.get(`/transaction/${id}/image/${imageId}`, {
                            responseType: 'blob',
                            cancelToken: CTS.token,
                        }),
                    ),
                );
                this.imagesData.imageUrls = loadedImages.map((response) =>
                    URL.createObjectURL(response.data),
                );
                this.imagesData.isLoaded = true;
            } catch (e) {
                this.imagesData.isError = true;
                console.info(e);
            } finally {
                this.imagesData.isLoading = false;
            }
        },
        imagesDialogClosed() {
            this.imagesData.cancelTokenSource?.cancel();
            this.imagesDialogVisible = false;
            setTimeout(() => {
                Object.assign(this.imagesData, imagesDataFactory());
            }, 0);
        },
        onUploadSuccess(transaction, response) {
            if (Array.isArray(transaction.imagesIds)) {
                transaction.imagesIds.push(response.imageId);
            } else {
                this.$set(transaction, 'imagesIds', [response.imageId]);
            }
        },
        addColListener(prop) {
            const col = this.$refs[`${prop}Col`];
            if (col) {
                const el = this.$el.querySelector(`.${col.columnId}`);
                if (el) {
                    el.classList.add('cursor-pointer');
                    el.addEventListener(
                        'click',
                        this.handleColHeaderClick.bind(this, prop),
                    );
                    this.customListeners.push({
                        el,
                        type: 'click',
                        func: this.handleColHeaderClick,
                    });
                }
            }
        },
        removeListeners() {
            this.customListeners.forEach(({ el, type, func }) => {
                el.removeEventListener(type, func);
            });
        },
        handleColHeaderClick(prop) {
            switch (this.transactionData.sortOrders[prop]) {
                case 'ascending':
                    this.transactionData.sortOrders[prop] = 'descending';
                    break;

                case 'descending':
                    this.transactionData.sortOrders[prop] = null;
                    break;

                case null:
                    this.transactionData.sortOrders[prop] = 'ascending';
                    break;

                default:
                    break;
            }
        },
        toggleSortClasses() {
            Object.entries(this.transactionData.sortOrders).forEach(
                ([prop, order]) => {
                    const col = this.$refs[`${prop}Col`];
                    if (col) {
                        const el = this.$el.querySelector(`.${col.columnId}`);
                        if (el) {
                            el.classList.remove('ascending');
                            el.classList.remove('descending');
                            if (order) {
                                el.classList.add(order);
                            }
                        }
                    }
                },
            );
        },
        getTransactionTime(date) {
            const hoursOffset = new Date().getTimezoneOffset() / 60;

            return moment(date)
                .add({ hours: hoursOffset })
                .format('YYYY-MM-DD HH:mm');
        },
        showRemoveButton(transaction) {
            if (transaction.source === 'staff') return;
            if (
                this.authUser.id !== transaction.created_by &&
                this.isAllowed('ACCESS_REMOVE_OTHER_TRANSACTION')
            ) {
                return true;
            }
            if (
                this.authUser.id === transaction.created_by &&
                this.isAllowed('ACCESS_REMOVE_SELF_TRANSACTION')
            ) {
                return true;
            }
        },
        showEditButton(transaction) {
            if (!['income', 'expense'].includes(transaction.type)) return;
            if (transaction.source === 'staff') return;
            if (
                this.authUser.id !== transaction.created_by &&
                this.isAllowed('ACCESS_EDIT_OTHER_TRANSACTION')
            ) {
                return true;
            }
            if (
                this.authUser.id === transaction.created_by &&
                this.isAllowed('ACCESS_EDIT_SELF_TRANSACTION')
            ) {
                return true;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.transaction-type {
    &.income {
        color: #3bbe3b;
    }

    &.expense {
        color: #e16767;
    }

    &.transfer {
        color: #aeaeae;
    }
}

.fee {
    margin-left: 10px;
    opacity: 0.5;
}

.comment-link {
    font-size: 0.9em;
}
::v-deep .cursor-pointer {
    cursor: pointer;
}
.transaction-image {
    min-height: 200px;
    min-width: 200px;
    display: block;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.el-pagination {
    margin: 15px 0;
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .button {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0 !important;
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
</style>
