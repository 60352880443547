<!-- eslint-disable max-len -->
<!-- eslint-disable no-empty -->
<template lang="pug">
.admins-table-wrapper
    el-table.table.w-full(:data="usersGetter" v-loading="usersLoadingGetter")
        el-table-column(prop="id", label="#", width="50")

        el-table-column(prop="email", label="Почта")
            template(slot-scope="scope")
                span {{ scope.row.email }}
                el-tag.bage_me(type="success" size="mini" v-if="scope.row.id === authUser.id") Это вы

        el-table-column(prop="name", label="ФИО")

        el-table-column(prop="registrationDate", label="Дата регистрации")
            template(slot-scope="scope")
                span {{ scope.row.registrationDate | moment("DD/MM/YYYY HH:mm") }}

        el-table-column(v-if="isAllowed('ACCESS_2FA_RESET')" prop="enabled2fa" label="2FA" align="center" width="80px")
            template(slot-scope="scope")
                // Если аутентификатор не привязан и текущий пользователь - SUPERUSER,
                // то даем возможность разрешить работу без аутентификатора
                el-tooltip(
                    v-if="isAllowed('SUPERUSER') && !scope.row.enabled2fa && !scope.row.doNotDemand2FA"
                    content="Разрешить работу без аутентификатора"
                    :open-delay="300"
                )
                    i.el-icon-document-checked.text-green-600.cursor-pointer(
                        @click="authenticatorDemandOnInit(scope.row, true)"
                    )

                // Если аутентификатор не привязан, но работа разрешена и текущий пользователь - SUPERUSER,
                // то даем возможность запретить работу без аутентификатора
                el-tooltip(
                    v-if="isAllowed('SUPERUSER') && !scope.row.enabled2fa && scope.row.doNotDemand2FA"
                    content="Запретить работу без аутентификатора"
                    :open-delay="300"
                )
                    i.el-icon-document-delete.text-yellow-600.cursor-pointer(
                        @click="authenticatorDemandOnInit(scope.row, false)"
                    )

                el-tooltip(
                    v-else-if="scope.row.enabled2fa"
                    content="Сбросить привязанный аутентификатор"
                    :open-delay="300"
                )
                    i.el-icon-unlock.text-red-600.cursor-pointer(@click="resetAuthenticator(scope.row)")


        el-table-column(width="100")
            template(slot-scope="scope")
                el-button(
                    size="mini"
                    v-if="scope.row.id !== authUser.id"
                    @click="openModal('AdminRightsPopup', scope.row)"
                    type="warning" plain
                ) Права

        el-table-column(width="60")
            template(slot-scope="scope")
                el-button(
                    size="mini"
                    v-if="scope.row.id !== authUser.id"
                    @click="event => deleteAdmin(scope.row, event)"
                    type="danger"
                    plain
                    circle
                )
                    .i.el-icon-delete.pointer-events-none

    .admins-table-wrapper__create-btn
        el-button(@click.native="openModal('NewAdminPopup')", type="primary", circle="")
            i.el-icon-plus

    AdminRightsPopup(
        ref="AdminRightsPopup"
        @onSave="getUsers"
    )

    NewAdminPopup(ref="NewAdminPopup" @onSubmit="getUsers")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AdminRightsPopup from './modals/AdminRightsPopup';
import NewAdminPopup from './modals/NewAdminPopup';

export default {
    components: {
        NewAdminPopup,
        AdminRightsPopup,
    },
    data() {
        return {
            authenticatorStateUpdating: false,
        };
    },
    computed: {
        ...mapGetters('users', ['usersGetter', 'usersLoadingGetter']),
        ...mapGetters(['authUser']),
    },
    methods: {
        ...mapActions('users', ['getUsersAction', 'removeUserAction']),
        ...mapActions('authenticator', [
            'updateDemandKeyAction',
            'reset2faAction',
        ]),
        init() {
            this.getUsers();
        },
        async getUsers() {
            await this.getUsersAction();
        },
        openModal(modal, data) {
            this.$refs[modal].open(data);
        },
        async resetAuthenticator(admin) {
            if (this.authenticatorStateUpdating) return;

            try {
                this.authenticatorStateUpdating = true;

                await this.$confirm(
                    `Вы уверены, что хотите отвязать двухфакторный аутентификатор администратора
                ${admin.email}/${admin.name}?`,
                    'Внимание',
                    {
                        confirmButtonText: 'Продолжить',
                        cancelButtonText: 'Отмена',
                        type: 'warning',
                    },
                );

                await this.reset2faAction({ user_id: admin.id });
                this.$notify({
                    title: 'Успешно',
                    message: 'Аутентификатор сброшен',
                    type: 'success',
                    duration: 4000,
                });
                this.getUsers();
            } catch (error) {
                console.log(error);
            } finally {
                this.authenticatorStateUpdating = false;
            }
        },
        async authenticatorDemandOnInit(admin, value) {
            if (this.authenticatorStateUpdating) return;

            const messageText = `Вы уверены, что хотите ${
                value ? 'разрешить' : 'запретить'
            } администратору работу без аутентификатора ${admin.email}/${
                admin.name
            }?`;

            try {
                this.authenticatorStateUpdating = true;

                await this.$confirm(messageText, 'Внимание', {
                    confirmButtonText: 'Продолжить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                });

                await this.updateDemandKeyAction({ userId: admin.id, value });
                this.$notify({
                    title: 'Успешно',
                    message: 'Настройка сохранена',
                    type: 'success',
                    duration: 4000,
                });
                this.getUsers();
            } catch (error) {
                console.log(error);
            } finally {
                this.authenticatorStateUpdating = false;
            }
        },
        async deleteAdmin(admin, event) {
            if (this.authenticatorStateUpdating) return;

            try {
                this.authenticatorStateUpdating = true;

                await this.$confirm(
                    `Вы уверены, что хотите удалить администратора ${admin.email}/${admin.name}?`,
                    'Внимание',
                    {
                        confirmButtonText: 'Продолжить',
                        cancelButtonText: 'Отмена',
                        type: 'warning',
                    },
                );
                await this.removeUserAction(admin.id);
                this.$notify({
                    title: 'Успешно',
                    message: 'Администратор удален',
                    type: 'success',
                    duration: 4000,
                });
                this.getUsers();
            } catch (error) {
                console.log(error);
            } finally {
                this.authenticatorStateUpdating = false;
            }

            event.target.blur();
        },
    },
};
</script>

<style lang="scss">
.admins-table-wrapper {
    &__create-btn {
        position: fixed;
        z-index: 10;
        right: 15px;
        bottom: 25px;
        width: 50px;
    }

    .el-button span {
        pointer-events: none;
    }

    .bage_me {
        margin-left: 7px;
    }
}
</style>
