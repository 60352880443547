<template lang="pug">
.account-table
    el-table(
      :data="accountsGetter"
      v-loading="loadingAccountsGetter"
    )
      el-table-column
        template(slot="header")
          | Капитал
        template(slot-scope="scope")
          | {{ scope.row.name }}

      el-table-column(align="right")
        template(
          slot="header"
          slot-scope="scope"
        )
          template
            i.el-icon-loading.loading-icon(v-if="totalBalanceLoading")
            div(v-else)
                el-button.refresh-icon(
                    v-if="convertingError"
                    :title="convertingError"
                    :icon="refreshIcon"
                    size="large"
                    @click.native="getTotalBalance"
                )
                span(v-else) {{ totalBalance | money('USD') }}
        template(slot-scope="scope")
          template
            i.el-icon-loading(v-if="loadingAccountsBalancesGetter")
            span(v-if="!loadingAccountsBalancesGetter")
              | {{ getBalance(scope.row, 'balance') | money(scope.row.currency) }}

      el-table-column(align="right")
        template(
          slot="header"
          slot-scope="scope"
        )
          | Фактический баланс
        template(slot-scope="scope")
            template
              i.el-icon-loading(v-if="loadingAccountsBalancesGetter")
              span(v-if="!loadingAccountsBalancesGetter")
                | {{ getBalance(scope.row, 'actualBalance') | money(scope.row.currency) }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import accountUtils from '@/components/utils/accountUtils';
import { CURRENCIES, CURRENCY_CHAR_MAP } from '@/utils/constants';
import errors from '@/utils/errors';

export default {
    name: 'AccountTable',
    data() {
        return {
            CURRENCY_CHAR_MAP,
            totalBalance: 0,
            totalBalanceLoading: false,
            convertingError: null,
            refreshIcon: 'el-icon-refresh-right',
        };
    },
    computed: {
        ...mapGetters(['groupId']),
        ...mapGetters('accounts', [
            'loadingAccountsGetter',
            'accountsGetter',
            'loadingAccountsBalancesGetter',
            'accountsBalancesGetter',
        ]),
    },

    mounted() {
        this.totalBalanceLoading = true;
        Promise.all([
            this.getAccountsAction({ groupId: this.groupId }),
            this.getAccountsBalancesAction({ groupId: this.groupId }),
        ])
            .then(this.getTotalBalance)
            .finally(() => {
                this.totalBalanceLoading = false;
            });
    },
    methods: {
        ...mapActions('accounts', [
            'getAccountsAction',
            'getAccountsBalancesAction',
            'convertBalancesAction',
        ]),
        ...mapActions(['utilsConvertCurrencies']),
        getBalance(row, prop) {
            if (!row || !this.accountsBalancesGetter[row.id]) return 0;
            return this.accountsBalancesGetter[row.id][prop] || 0;
        },
        async getTotalBalance() {
            if (!this.totalBalanceLoading) this.totalBalanceLoading = true;
            try {
                const balances = await this.convertBalancesAction({
                    converter: this.utilsConvertCurrencies,
                    currency: CURRENCIES.USD,
                });
                this.totalBalance = accountUtils.sumBalances(balances);
                this.convertingError = null;
            } catch (_) {
                this.convertingError = errors.FAILED_CONVERT_CURRENCY;
            } finally {
                if (this.totalBalanceLoading) this.totalBalanceLoading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.loading-icon {
    padding: 5px;
}
.refresh-icon {
    cursor: pointer;
    border: none;
    padding: 5px;
}
</style>
