<template lang="pug">
div(v-loading="!isInitialLoaded")#app
    router-view
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['isInitialLoaded']),
    },
};
</script>

<style lang="scss">
body {
    margin: 0;
    padding: 0;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.flex {
    display: flex;
}

.flex-start {
    justify-content: start;
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.mr-1 {
    margin-right: 0.2rem !important;
}

.ml-1 {
    margin-left: 0.2rem !important;
}

.mt-1 {
    margin-top: 0.2rem !important;
}

.mr-2 {
    margin-right: 0.3rem !important;
}

.ml-2 {
    margin-left: 0.3rem !important;
}

.mt-2 {
    margin-top: 0.3rem !important;
}

.mb-2 {
    margin-bottom: 0.3rem !important;
}

.mr-4 {
    margin-right: 0.6rem !important;
}

.ml-4 {
    margin-left: 0.6rem !important;
}

.mt-4 {
    margin-top: 0.6rem !important;
}

.mb-4 {
    margin-bottom: 0.6rem !important;
}

.mr-6 {
    margin-right: 1rem !important;
}

.ml-6 {
    margin-left: 1rem !important;
}

.mt-6 {
    margin-top: 1rem !important;
}

.mb-6 {
    margin-bottom: 1rem !important;
}

.w-full {
    width: 100% !important;
}

.full-screen {
    width: 100vw;
    height: 100vh;
}

.p-1 {
    padding: 0.2rem;
}

.p-4 {
    padding: 0.6rem;
}

.p-6 {
    padding: 1rem;
}

.pb-4 {
    padding-bottom: 0.6rem;
}

.text-bold {
    font-weight: bold;
}

.fs-16 {
    font-size: 16px;
}

.bold {
    font-weight: bold;
}
</style>
