<template lang="pug">
.create-property-form
      el-dialog(
        title="Создание счета"
        :visible="show"
        :destroy-on-close="true"
        :before-close="close"
      )
        el-form(:model="form" :rules="rules" ref="form")
            el-form-item(prop="name" label="Название")
              el-input(v-model="form.name" placeholder="Укажите название" autocomplete="off")
            el-form-item(prop="currency" label="Валюта счета")
              el-select(v-model="form.currency" placeholder="Укажите валюту счета")
                el-option(v-for="(char, key) in CURRENCY_CHAR_MAP" :key="key" :label="char", :value="key")
                  span {{ char }} - {{ key }}

            el-form-item(prop="amount" label="Начальный баланс")
              el-input-number.w-full(
                v-model.number="form.amount"
                placeholder="Укажите начальный баланс"
                autocomplete="off"
            )

        span.dialog-footer(slot="footer")
          el-button(@click="close" :disabled="isLoading") Отмена
          el-button(type="primary" :disabled="isLoading" :loading="isLoading" @click="addAction") Сохранить

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
    CATEGORY_TYPE_MAP,
    CURRENCY_CHAR_MAP,
    DEFAULT_CURRENCY,
} from '@/utils/constants';

export default {
    name: 'AccountsModal',
    data() {
        return {
            show: false,
            isLoading: false,
            form: {
                name: '',
                currency: DEFAULT_CURRENCY,
                amount: 0,
            },
            rules: {
                name: {
                    required: true,
                    message: 'Введите название',
                    trigger: 'blur',
                },
                currency: {
                    required: true,
                    message: 'Выберите валюту',
                    trigger: 'blur',
                },
            },
            CURRENCY_CHAR_MAP,
            CATEGORY_TYPE_MAP,
        };
    },
    computed: {
        ...mapGetters(['groupId']),
    },
    methods: {
        ...mapActions('accounts', [
            'createAccountAction',
            'getAccountsAction',
            'accountsBalancesGetter',
        ]),
        close() {
            this.show = false;
        },
        open() {
            this.show = true;
        },
        async addAction() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.isLoading = true;
                    try {
                        await this.createAccountAction({
                            ...this.form,
                            groupId: this.groupId,
                        });
                        this.getAccountsAction({ groupId: this.groupId });
                        this.accountsBalancesGetter({ groupId: this.groupId });
                        this.close();
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.create-property-form {
    .el-dialog__wrapper {
        > div {
            max-width: 350px;

            .el-select {
                width: 100%;
            }
        }
    }
}
</style>
