<template lang="pug">
.passcode-input-wrapper
    el-row.flex.authentication-code(:gutter="10")
        el-col(v-for="row in Object.keys(authenticationCode)" :key="row")
            el-input(
                @keydown.delete.native="changeFocus(row)"
                v-model="authenticationCode[row]"
                @input="checkInput(row, $event)"
                @keydown.enter.native="submit"
                @paste.native="onPaste"
                :ref="'input-' + row"
                inputmode="numeric"
                pattern="[0-9]"
                :maxlength="1"
                type="number"
            )
</template>
<script>
// object with required number of keys { 1: '' , 2: '', ... }
const createAuthFields = (length) =>
    [...Array(length).keys()].reduce((acc, i) => ({ ...acc, [i + 1]: '' }), {});

export default {
    name: 'PasscodeInput',
    props: {
        passLength: {
            type: Number,
            default: 6,
        },
        // eslint-disable-next-line vue/require-default-prop
        updateCode: {
            type: Function,
        },
        // eslint-disable-next-line vue/require-default-prop
        submit: {
            type: Function,
        },
    },
    data() {
        return {
            authenticationCode: createAuthFields(this.passLength),
        };
    },
    computed: {
        authCodeKeys() {
            return Object.keys(this.authenticationCode);
        },
        authCodeValue() {
            return Object.values(this.authenticationCode).join('');
        },
    },
    methods: {
        onPaste(event) {
            const string = this.filterNumbers(
                event.clipboardData.getData('text'),
            );

            this.authCodeKeys.forEach((k, i) => {
                this.authenticationCode[k] = string[i] || '';

                if (string[i]) this.$refs[`input-${k}`][0].focus();
            });

            this.updateCode(this.authCodeValue);

            if (string.length === this.authCodeKeys.length) this.submit();
        },
        changeFocus(row) {
            if (row === '1') return;

            if (!this.authenticationCode[+row]) {
                const previousInput = +row - 1;

                this.$refs[`input-${previousInput}`][0].focus();
                this.authenticationCode[previousInput] = '';
                this.updateCode(this.authCodeValue);
            }
        },
        checkInput(row, v) {
            if (v.length > 1) v = v[0];

            const string = this.filterNumbers(v);

            this.authenticationCode[row] = string.length ? string : '';

            this.updateCode(this.authCodeValue);

            if (string.length === 1 && row < this.authCodeKeys.length)
                this.$refs[`input-${+row + 1}`][0].focus();
        },
        resetFields() {
            this.authenticationCode = createAuthFields(this.passLength);
            this.updateCode(this.authCodeValue);
        },
        filterNumbers: (v = '') =>
            v
                .split('')
                .filter((s) => /^[0-9]$/.test(s))
                .join(''),
    },
};
</script>
<style lang="scss">
.authentication-code {
    margin-bottom: 0;

    .el-input__inner {
        border-color: #212129;
        padding: 0 12px;
        background: #cccccc22;
    }

    .el-input__inner:focus {
        border-color: teal;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.auth-error {
    .el-input__inner {
        border-color: #f56c6c;
    }
}
</style>
