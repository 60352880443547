import store from '@/store/store';

import { RIGHT_PREFIXES } from './constants';

export default function isAllowed(
    rights,
    options = { type: RIGHT_PREFIXES.RIGHTS, checkType: 'some' },
) {
    const storeInst = this && this.$store ? this.$store : store;

    if (!Array.isArray(rights)) rights = [rights];

    const currentRights = storeInst.state.auth
        ? storeInst.state.auth.access
        : null;

    if (!currentRights || !currentRights[options.type]) return false;

    if (currentRights[options.type].SUPERUSER) return true;

    return rights[options.checkType](
        (right) => !!currentRights[options.type][right],
    );
}
