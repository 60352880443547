<template lang="pug">
.step-two.flex.flex-col.items-center
    el-card.mt-12(v-loading="isLoading")
        .qr
            qrcode-vue(v-if="qr" :value="qr" :size="200" level="L")

    .flex.text-center.flex-col  
        .my-3
            i.el-icon-loading(v-if="isLoading")
            Copyable(v-else :value="secretCodeGetter")
        span.max-w-xs.text-sm.opacity-60 Если вы не можете отсканировать QR-код, введите этот код вручную в приложение.

    el-row.w-64.mt-8(:gutter="10")
        el-col(:span="10")
            el-button.w-full(@click="$emit('previousStep')") Назад
        el-col(:span="14")
            el-button.w-full(type="primary" @click="$emit('nextStep')") Продолжить
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { mapActions, mapGetters } from 'vuex';

import Copyable from '../../ui/Copyable';

export default {
    components: { QrcodeVue, Copyable },
    data() {
        return {
            isLoading: false,
            qr: 'null',
        };
    },
    computed: {
        ...mapGetters('authenticator', ['secretCodeGetter']),
    },
    mounted() {
        this.getCode();
    },
    methods: {
        ...mapActions('authenticator', ['enableAuthenticatorAction']),
        async getCode() {
            try {
                this.isLoading = true;
                this.qr = await this.enableAuthenticatorAction();
            } catch (error) {
                if (error.response.status === 409) {
                    this.$router.push({ path: '/' });
                }
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    color: #000;
}

.links-block a {
    color: black;
}

.qr {
    height: 200px;
    width: 200px;
}

.download-button:hover {
    cursor: pointer;
    position: relative;
    top: -2px;
}
</style>
