/**
 * @author feod0r
 */

export default {
    isAuthorized: (state) => state.auth.status === true,
    isInitialLoaded: (state) => state.isInitialLoaded,
    authUserAccess: (state) => state.auth.access,
    merchantsGetter(state) {
        return state.propertiesData.merchants || [];
    },
    merchantsMapGetter(state) {
        return state.propertiesData.merchants.reduce((acc, merchant) => {
            if (!acc[`${merchant.name}--${merchant.currency}`])
                acc[`${merchant.name}--${merchant.currency}`] = merchant;
            return acc;
        }, {});
    },
    propertiesData: (state) => {
        return Object.fromEntries(
            Object.entries(state.propertiesData).map(([key, list]) => [
                key,
                list && list.length
                    ? list.filter((v) => !v.is_removed && v.source !== 'staff')
                    : [],
            ]),
        );
    },
    propertiesDataWithRemoved: (state) => {
        return state.propertiesData;
    },
    transactionData: (state) => state.transactionData,
    transactionDataFilters: (state) => state.transactionData.filters,
    merchantBalancesData: (state) => state.merchantBalancesData,
    group: (state) => state.group,
    groupId: (state) => state.group.selected,
    authUser: (state) =>
        state.auth && state.auth.user ? state.auth.user : null,
    isSuperUserGetter: (state) => {
        try {
            return Boolean(state.auth.access.rights.SUPERUSER);
        } catch (_error) {
            return false;
        }
    },
};
