<template lang="pug">
.create-transaction-form
  el-dialog(
    :title="`${titleType} ${title}`"
    :visible="isVisible"
    @close="$emit('updateVisibleStatus', false)"
    @open="!editableRow ? resetForm() : ''"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  )
    el-form(
      :model="form"
      :rules="rules"
      ref="form"
      :disabled="isLoading"
    )
      el-form-item(
        prop="accountId"
        label="Счет"
      )
        AccountsSelect.select(v-model="form.accountId" size="large")

      el-form-item(
        prop="categoryPath"
        label="Категория"
      )
        CategoriesCascader(
          v-model="form.categoryPath"
          type="manager"
          size="large"
          :filters="{project: 'accountancy'}"
          :props="{multiple: false}"
        )

      el-form-item(
        prop="geoId"
        label="ГЕО"
      )
        el-select.select(
          placeholder="Выберите ГЕО"
          v-model="form.geoId"
          filterable
          clearable
        )
          el-option(
            v-for="geo of propertiesData.geo"
            :key="geo.id"
            :label="geo.title"
            :value="geo.id"
          )

      el-form-item.w-full(
        prop="amount"
        label="Сумма операции"
      )
        .flex.items-center.justify-stretch.w-full.pt-2
          el-input-number.w-full(
            v-model="form.amount"
            :step="1"
            placeholder="Укажите сумму"
            autocomplete="off"
          )
          .tag.ml-4(size="medium" :title="currency") {{ currency }}

      el-form-item(
        prop="fee"
        label="Комиссия за операцию (не обязательно)"
      )
        .flex.items-center.justify-stretch.w-full.pt-2
            el-input-number.w-full(
              v-model="form.fee"
              :step="1"
              placeholder="Укажите комиссию"
              autocomplete="off"
            )
            .tag.ml-4(size="medium" :title="currency") {{ currency }}

      el-form-item(
        prop="date"
        label="Дата операции"
      )
        el-date-picker.datepicker-input(
          v-model="form.date"
          type="date"
          placeholder="Выберите дату"
        )

      el-form-item(
        prop="comment"
        label="Комментарий"
      )
        el-input(
          v-model="form.comment"
          type="textarea"
          :rows="2"
          placeholder="Комментарий (не обязательно)"
          autocomplete="off"
        )

    span.dialog-footer(slot="footer")
      el-button(
        @click="$emit('updateVisibleStatus', false)"
        :disabled="isLoading"
      ) Отмена
      el-button(
        type="primary"
        :loading="isLoading"
        @click="submitTransaction"
      ) Подтвердить

  insert-reason-form(
    :isVisible="showReasonModal"
    :isLoading="isLoading"
    @sendEditingTransaction="sendEditingTransaction"
    @updateReasonVisibleStatus="showReasonModal = false"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { editTransaction, createTransaction } from '@/api/transaction';
import AccountsSelect from '@/components/ui/AccountsSelect';
import CategoriesCascader from '@/components/ui/CategoriesCascader';
import { CURRENCY_CHAR_MAP, DEFAULT_CURRENCY } from '@/utils/constants';
import removeTimezoneOffset from '@/utils/removeTimezoneOffset';

import InsertReasonForm from './InsertReasonForm';

const initFormData = (row) => {
    row = row || {};
    return {
        accountId: row.account_id
            ? row.account_id
            : row.merchant_name
              ? `${row.merchant_name}--${row.currency}`
              : null,
        categoryPath: row.category_path ? row.category_path.split('.') : [],
        amount: Math.abs(row.amount) || 0,
        fee: row.fee || 0,
        comment: row.comment || '',
        date: row.source_payout_date || null,
        transactionId: row.id || null,
        geoId: row.geo_id || null,
    };
};

export default {
    name: 'CreateTransactionForm',
    components: { CategoriesCascader, InsertReasonForm, AccountsSelect },
    // eslint-disable-next-line vue/require-prop-types
    props: ['type', 'editableRow'],
    data() {
        return {
            isLoading: false,
            form: initFormData(),
            CURRENCY_CHAR_MAP,
            showReasonModal: false,
        };
    },
    computed: {
        ...mapGetters([
            'propertiesData',
            'transactionData',
            'groupId',
            'merchantsMapGetter',
        ]),
        ...mapGetters('accounts', ['accountsMapGetter']),
        title() {
            return this.type === 'income' ? 'дохода' : 'расхода';
        },
        titleType() {
            return this.isEditing ? 'Редактирование' : 'Создание';
        },
        currency() {
            const value = this.form.accountId;
            const defaultCurrency =
                this.editableRow?.currency || DEFAULT_CURRENCY;

            if (!value) return defaultCurrency;

            const obj = isNaN(value)
                ? this.merchantsMapGetter[value]
                : this.accountsMapGetter[value];

            let currency = defaultCurrency;
            if (obj && obj.currency) {
                if (obj.name.startsWith('metacash')) {
                    const [currencyName] = obj.currency.split('-');
                    currency = currencyName;
                } else {
                    currency = obj.currency;
                }
            }
            return currency;
        },
        isVisible() {
            return !!this.type;
        },
        isEditing() {
            return !!this.editableRow;
        },
        rules() {
            const rules = {
                categoryPath: {
                    required: true,
                    message: 'Выберите категорию',
                    trigger: 'change',
                },
                amount: {
                    required: true,
                    message: 'Заполните поле',
                    trigger: 'change',
                },
                fee: {
                    required: true,
                    message: 'Заполните поле',
                    trigger: 'change',
                },
                date: {
                    required: true,
                    message: 'Выберите дату',
                    trigger: 'change',
                },
            };

            if (!this.isEditing) {
                rules.accountId = {
                    required: true,
                    message: 'Выберите аккаунт',
                    trigger: 'change',
                };
            }

            return rules;
        },
    },
    watch: {
        editableRow(value) {
            if (!value) return;

            this.form = initFormData(value);
        },
    },
    methods: {
        ...mapActions(['loadTransactionData', 'loadPropertiesData']),
        resetForm() {
            this.form = initFormData();
        },
        submitTransaction() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    this.isLoading = true;
                    try {
                        if (this.isEditing) {
                            this.showReasonModal = true;
                            return true;
                        }
                        await createTransaction({
                            type: this.type,
                            ...this.form,
                            amount:
                                Math.abs(this.form.amount) *
                                (this.type === 'income' ? 1 : -1),
                            groupId: this.groupId,
                            date: removeTimezoneOffset(this.form.date),
                        });
                        this.$notify({
                            title: 'Успешно',
                            message: 'Транзакция создана',
                            type: 'success',
                            duration: 3000,
                        });

                        this.$emit('updateVisibleStatus', false);

                        if (this.isAllowed('ACCESS_OPERATIONS')) {
                            this.loadTransactionData(
                                this.transactionData.currentPage,
                            );
                        }

                        this.loadPropertiesData();
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
        async sendEditingTransaction(reason) {
            this.isLoading = true;

            try {
                await editTransaction(this.editableRow.id, {
                    ...this.form,
                    source_payout_date: removeTimezoneOffset(
                        new Date(this.form.date),
                    ),
                    reason,
                    currency: this.currency,
                    account_id: this.form.accountId,
                    geo_id: this.form.geoId,
                    amount:
                        Math.abs(this.form.amount) *
                        (this.type === 'income' ? 1 : -1),
                });
                this.$notify({
                    title: 'Успешно',
                    message: 'Транзакция обновлена',
                    type: 'success',
                    duration: 3000,
                });
                this.$emit('updateVisibleStatus', false);

                if (this.isAllowed('ACCESS_OPERATIONS')) {
                    this.loadTransactionData(this.transactionData.currentPage);
                }

                this.loadPropertiesData();
            } finally {
                this.isLoading = false;
                this.showReasonModal = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.create-transaction-form {
    ::v-deep {
        .select,
        .el-date-editor {
            width: 100%;
        }

        .el-form-item {
            margin-bottom: 15px;
        }

        .el-form-item__label {
            margin-bottom: 0;
            padding-bottom: 0;
            line-height: inherit;
        }
    }
}
.tag {
    display: inline-block;
    text-align: center;
    width: 100px;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
    font-size: 12px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    background-color: #f5f7fa;
    color: #909399;
}
</style>
