<template lang="pug">
  el-select.w-full(
    :size="$attrs.size"
    :loading="group.isLoading"
    :value="group.selected"
    @input="$store.dispatch('changeGroup', $event)"
  )
    el-option(
      v-for="option in group.list"
      :key="option.id"
      :value="option.id"
      :label="option.name"
    )
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'GroupSelect',
    computed: {
        ...mapGetters(['group']),
    },
};
</script>
