<template lang="pug">
el-dialog(
  title="Добавить администратора"
  :visible.sync="show"
  :before-close="handleClosePopup"
  :close-on-click-modal="false"
  width="400px")

  el-form(ref="AdminForm" :model="form" :rules="rules")
    el-form-item(label="ФИО" prop="fio")
      el-input(v-model="form.name")
    el-form-item(label="Email" prop="email")
      el-input(v-model="form.email")
    el-form-item(label="Пароль" prop="password")
      el-input(v-model="form.password" show-password)

  span.dialog-footer(slot="footer")
    el-button(@click="close" type="info") Отмена
    el-button(@click="submit" type="primary") Добавить

</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            show: false,
            form: {
                fio: null,
                name: null,
                password: null,
            },
            rules: {
                name: [
                    { required: true, message: 'Введите ФИО', trigger: 'blur' },
                ],
                email: [
                    {
                        required: true,
                        message: 'Введите почту',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: 'Введите пароль',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions('users', ['createUserAction']),
        init() {
            this.form = {
                name: null,
                email: null,
                password: null,
            };
        },
        open() {
            this.show = true;
            this.init();
        },
        submit() {
            this.$refs.AdminForm.validate(async (valid) => {
                if (valid) {
                    await this.createUserAction(this.form);
                    this.$notify({
                        title: 'Успешно',
                        message: 'Администратор добавлен',
                        type: 'success',
                        duration: 4000,
                    });
                    this.$emit('onSubmit');
                    this.close();
                }
            });
        },
        close() {
            this.show = false;
        },
        handleClosePopup() {
            this.close();
            this.$refs.AdminForm.resetFields();
        },
    },
};
</script>
