<template lang="pug">
.settings-page
    el-tabs(tab-position="top" @tab-click="tabClick" v-model="activeTab")
        el-tab-pane(v-if="isAllowed('ACCESS_ACCOUNTS')" name="accounts")
            template(slot="label")
                span Счета
                i(class="el-icon-coin")

            AccountsTab

        el-tab-pane(v-if="isAllowed('ACCESS_CATEGORIES')" name="transactions-category")
            template(slot="label")
                span Категории транзакций
                i(class="el-icon-folder-opened")

            TransactionCategoriesTab

        el-tab-pane(v-if="isAllowed('ACCESS_ADMINISTRATORS')" name="admins")
            template(slot="label")
                span Администраторы
                i(class="el-icon-user")

            AdminsTab

        el-tab-pane(v-if="isAllowed('ACCESS_ROLES')" name="roles")
            template(slot="label")
                span Конфигуратор ролей
                i(class="el-icon-star-off")

            RolesTab

</template>

<script>
import { mapActions } from 'vuex';

import AccountsTab from '@/components/pages/settings/AccountsTab/AccountsTab';
import AdminsTab from '@/components/pages/settings/AdminsTab/AdminsTab.vue';
import RolesTab from '@/components/pages/settings/RolesTab/RolesTab.vue';
// eslint-disable-next-line max-len
import TransactionCategoriesTab from '@/components/pages/settings/TransactionCategoriesTab/TransactionCategoriesTab.vue';

export default {
    name: 'SettingsPage',
    components: {
        AccountsTab,
        TransactionCategoriesTab,
        AdminsTab,
        RolesTab,
    },
    data: () => ({
        activeTab: 'accounts',
    }),
    created() {
        this.setActiveTab();
    },
    methods: {
        ...mapActions('transactionCategoryRelations', ['getStructureAction']),
        ...mapActions('relationManager', [
            'getProjectRolesAction',
            'getManagerStructureAction',
        ]),
        ...mapActions('users', ['getUsersAction']),
        tabClick(tab) {
            if (tab.name === 'transactions-category') {
                this.getManagerStructureAction({
                    filters: { project: 'accountancy' },
                });
            }
            if (tab.name === 'roles') {
                this.getProjectRolesAction();
            }
            if (tab.name === 'admins') {
                this.getUsersAction();
            }
        },
        setActiveTab() {
            const rules = {
                ACCESS_ACCOUNTS: 'accounts',
                ACCESS_CATEGORIES: 'transactions-category',
                ACCESS_ADMINISTRATORS: 'admins',
                ACCESS_ROLES: 'roles',
            };

            this.activeTab = Object.entries(rules).reduce(
                (acc, [rule, tab]) => {
                    // Если в аккумуляторе есть значение, то возвращаем его
                    if (acc) {
                        return acc;
                    }

                    // Если есть право, то открываем этот таб
                    if (this.isAllowed(rule)) {
                        return tab;
                    }

                    // Не показываем ничего, если прав нет
                    return '';
                },
                '',
            );

            this.tabClick({ name: this.activeTab });
        },
    },
};
</script>

<style scoped lang="scss">
.settings-page {
    .el-tabs__header {
        width: 100%;

        i {
            margin-left: 10px;
        }
    }
}
</style>
