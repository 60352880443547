import requester from '@/utils/api';

const namespace = '/relation-manager';

const initialState = {
    structure: {
        loading: false,
        data: null,
    },
    categories: {
        loading: false,
        data: null,
    },
    roles: {
        loading: false,
        data: null,
    },
    rights: {
        loading: false,
        data: null,
    },
    managerRights: {
        data: null,
        loading: false,
    },
};

const actions = {
    /// ////////////// Categories
    async getAllStructureAction({ commit }, { filters, options }) {
        const changeStore = !(options && options.changeStore === false);

        const field = 'structure';

        if (changeStore) {
            commit('SET_LOADING', { field, value: true });
        }

        const structure = await requester.get(`${namespace}/structure`, {
            params: filters,
        });

        if (changeStore) {
            commit('SET_STRUCTURE', structure.data);

            commit('SET_LOADING', { field, value: false });
        }

        return structure.data;
    },

    async getManagerStructureAction({ commit }, { filters, options }) {
        const changeStore = !(options && options.changeStore === false);

        const field = 'structure';

        if (changeStore) {
            commit('SET_LOADING', { field, value: true });
        }
        const structure = await requester.get(
            `${namespace}/structure/manager`,
            { params: filters },
        );

        if (changeStore) {
            commit('SET_STRUCTURE', structure.data);

            commit('SET_LOADING', { field, value: false });
        }

        return structure.data;
    },

    async getAllCategoriesAction({ commit }, params) {
        const field = 'categories';

        commit('SET_LOADING', { field, value: true });

        const categories = await requester.get(`${namespace}/categories`, {
            params,
        });

        commit('SET_CATEGORIES', categories.data);

        commit('SET_LOADING', { field, value: false });

        return categories.data;
    },

    async createCategoryAction(_, form) {
        await requester.post(`${namespace}/categories`, form);
    },

    async updateCategoryAction(_, form) {
        await requester.put(`${namespace}/categories`, form);
    },

    /// ////////////// Roles

    async createRoleAction(_, form) {
        await requester.post(`${namespace}/roles`, form);
    },

    async getProjectRolesAction({ commit }) {
        const field = 'roles';

        commit('SET_LOADING', { field, value: true });
        const roles = await requester.get(`${namespace}/roles/project`);

        commit('SET_PROJECT_ROLES', roles.data);

        commit('SET_LOADING', { field, value: false });
    },

    async createRoleRightsAction(_, form) {
        await requester.post(`${namespace}/roles/rights`, form);
    },

    async getRoleRightsAction(_, roleId) {
        const { data } = await requester.get(
            `${namespace}/roles/${roleId}/rights`,
        );

        return data;
    },

    /// ////////////// Rights
    async getProjectRightsAction({ commit }, type) {
        const rights = await requester.get(`${namespace}/rights/project`, {
            params: { type },
        });

        commit('SET_PROJECT_RIGHTS', rights.data);

        return rights.data;
    },

    async getManagerRightsAction({ commit }, { type, managerId }) {
        const queryParams = {};

        if (type) {
            queryParams.type = type;
        }

        const rights = await requester.get(
            `${namespace}/rights/manager/${managerId}`,
            { params: queryParams },
        );

        commit('SET_MANAGER_RIGHTS', rights.data);

        return rights.data;
    },

    async updateRightsAction(_, form) {
        await requester.put(`${namespace}/rights/manager`, form);
    },
};

const mutations = {
    SET_STRUCTURE(state, structure) {
        state.structure.data = structure;
    },
    SET_CATEGORIES(state, categories) {
        state.categories.data = categories;
    },
    SET_LOADING(state, { field, value }) {
        state[field].loading = value;
    },
    SET_PROJECT_ROLES(state, data) {
        state.roles.data = data;
    },
    SET_PROJECT_RIGHTS(state, data) {
        state.rights.data = data;
    },
    SET_MANAGER_RIGHTS(state, data) {
        state.managerRights.data = data;
    },
};

const getters = {
    categoriesStructureGetter(state) {
        return state.structure.data ? state.structure.data.data : [];
    },
    categoriesStructureLoadingGetter(state) {
        return state.structure.loading;
    },
    categoriesGetter(state) {
        return state.categories.data ? state.categories.data.data.list : [];
    },
    categoriesLoadingGetter(state) {
        return state.categories.loading;
    },
    projectRolesGetter(state) {
        return state.roles.data ? state.roles.data.data : [];
    },
    projectRolesLoadingGetter(state) {
        return state.roles.loading;
    },
    projectRightsGetter(state) {
        return state.rights.data ? state.rights.data.data : [];
    },
    projectRightsWithoutSuperGetter(state) {
        return state.rights.data
            ? state.rights.data.data.filter((r) => r.key !== 'SUPERUSER')
            : [];
    },
    managersRightsGetter(state) {
        return state.managerRights.data ? state.managerRights.data.data : {};
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters,
};
