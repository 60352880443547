<template lang="pug">
el-drawer(:visible.sync="show" direction="rtl" size="30%" @closed="close")
    div(slot="title")
        .title {{ title }}
    .drawer-content
        el-form(:model="form" :rules="rules" ref="categoryForm")
            el-form-item(prop="categoryName" v-if="mode === 'create' && category")
                .label(slot="label") Родительская категория
                el-input(
                    :disabled="true"
                    :value="category.pathName.length ? category.pathName : category.label"
                    placeholder="Родительская категория"
                    autocomplete="off"
                )
            el-form-item.form-item(prop="name")
                .label(slot="label") Название
                el-select.full(
                    v-if="mode === 'create'"
                    v-model="form.name"
                    filterable allow-create
                    default-first-option
                    placeholder="Выберите или создайте категорию"
                    :loading="categoriesLoadingGetter"
                    clearable)
                    el-option(
                        v-if="categoriesGetter"
                        v-for="category of categoriesGetter"
                        :key="category.id"
                        :label="category.name"
                        :value="category.id"
                    )
                el-input(
                    v-if="mode === 'edit'"
                    v-model="form.name"
                    placeholder="Введите название категории"
                    autocomplete="off"
                )

            el-form-item.form-item(prop="type")
                .label(slot="label") Тип
                el-select.full(v-model="form.type"
                    @change="refreshCategories()"
                    placeholder="Выберите тип категории")
                    el-option(:label="name"
                    :value="type"
                    v-for="(name, type) of STRUCTURE_TYPES_TRANSLATES"
                    :key="type"
                )
            el-form-item
                .actions
                    el-button(@click="close") Отмена
                    el-button(type="primary" @click="submit" :loading="loading")
                        | {{ mode === "create" ? "Добавить" : "Редактировать" }}

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
    STRUCTURE_TYPES_TRANSLATES,
    STRUCTURE_TYPES,
    PROJECTS,
} from '@/utils/constants';

const initialForm = {
    name: '',
    type: STRUCTURE_TYPES.DEPARTMENT,
    parentPath: null,
};

export default {
    name: 'CategoryDrawer',
    data: () => ({
        STRUCTURE_TYPES_TRANSLATES,
        mode: 'create',
        category: null,
        loading: false,
        form: { ...initialForm },
        rules: {
            name: {
                required: true,
                message: 'Введите название категории',
                trigger: 'blur',
            },
        },
        show: false,
    }),
    computed: {
        ...mapGetters('relationManager', [
            'categoriesGetter',
            'categoriesLoadingGetter',
        ]),
        title() {
            if (this.mode === 'create' && !this.category)
                return 'Добавить корневую категорию';
            if (this.mode === 'create' && this.category)
                return `Добавить категорию`;
            if (this.mode === 'edit' && this.category)
                return `Редактировать категорию`;
            return 'Добавить категорию';
        },
    },
    methods: {
        ...mapActions('relationManager', [
            'getAllCategoriesAction',
            'createCategoryAction',
            'updateCategoryAction',
        ]),
        open(category) {
            this.show = true;
            this.setCategory(category);
            this.getAllCategoriesAction({
                includes: ['list'],
                project: PROJECTS.ACCOUNTANCY,
            });
        },
        close() {
            this.show = false;
            this.form = { ...initialForm };
            this.$refs.categoryForm.resetFields();
            this.$refs.categoryForm.clearValidate();
            this.mode = 'create';
            this.category = null;
        },
        setCategory(category) {
            this.category = category;
            this.form.parentPath =
                category && category.path ? category.path : null;
        },
        refreshCategories() {
            this.getAllCategoriesAction({
                includes: ['list'],
                project: PROJECTS.ACCOUNTANCY,
            });
        },
        setMode(mode) {
            this.mode = mode;
            this.form.name = this.category.label;
            this.form.type = this.category.type;
        },
        submit() {
            this.$refs.categoryForm.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    try {
                        const form = { ...this.form };
                        if (!Number.isNaN(+form.name)) {
                            form.categoryId = +form.name;
                            delete form.name;
                        }
                        if (this.mode === 'create') {
                            await this.createCategoryAction(form);
                            this.$notify({
                                title: 'Успешно',
                                message: `Категория создана`,
                                type: 'success',
                                duration: 4000,
                            });
                        } else {
                            await this.updateCategoryAction({
                                name: form.name,
                                type: form.type,
                                path: this.category.path,
                            });
                            this.$notify({
                                title: 'Успешно',
                                message: `Категория обновлена`,
                                type: 'success',
                                duration: 4000,
                            });
                            this.close();
                        }
                        this.$emit('onSubmit');
                    } finally {
                        this.loading = false;
                    }
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.drawer-content {
    padding: 0 20px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.subtitle {
    font-size: 14px;
}

.label {
    font-size: 14px;
    font-weight: bold;
    display: inline;
}

.full {
    width: 100%;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form-item {
    margin-top: -15px;
}
</style>
