<template lang="pug">
.step-three.flex.flex-col.items-center
    .max-w-lg.text-center.text-sm.opacity-60.mt-12
        | Пожалуйста, сохраните этот ключ на бумаге.
        | Этот ключ позволит вам восстановить ваш Google Authenticator в случае потери телефона.
    h3.text-center.my-3
        Copyable(:value="secretCodeGetter")

    el-row.w-64.mt-8(:gutter="10")
        el-col(:span="10")
            el-button.w-full(@click="$emit('previousStep')") Назад
        el-col(:span="14")
            el-button.w-full(type="primary" @click="$emit('nextStep')") Продолжить
</template>

<script>
import { mapGetters } from 'vuex';

import Copyable from '../../ui/Copyable';

export default {
    components: { Copyable },
    computed: {
        ...mapGetters('authenticator', ['secretCodeGetter']),
    },
};
</script>

<style lang="scss" scoped></style>
