<template lang="pug">
.header
    el-row.flex.items-center
        el-col(:span="7")
            el-menu.menu(mode="horizontal" :router="true" :default-active="activeLink")
                template(v-for="route in routeList()")
                    el-menu-item(v-if="showTab(route)" :index="route.path")
                        | {{ route.meta.title }}

        el-col(:span="8").action-buttons
            el-button-group
                el-button(
                    v-if="isAllowed('ACCESS_INCOME_TRANSACTION_CREATE')"
                    type="success"
                    size="mini"
                    icon="el-icon-circle-plus-outline"
                    @click="() => showCreateTransactionForm('income')"
                ) Доход
                el-button(
                    v-if="isAllowed('ACCESS_EXPENSE_TRANSACTION_CREATE')"
                    type="danger"
                    size="mini"
                    icon="el-icon-remove-outline"
                    @click="() => showCreateTransactionForm('expense')"
                ) Расход
                el-button(
                    v-if="isAllowed('ACCESS_TRANSFER_TRANSACTION_CREATE')"
                    size="mini"
                    icon="el-icon-sort"
                    @click="() => transferFormShow = true"
                ) Перевод

        el-col.flex.justify-center(:span="1")
            el-tooltip(v-if="!isTwoFactorEnabled" content="Необходимо настроить двухфакторную аутентификацию")
                router-link(to="/enable-google-authenticator")
                    i.el-icon-lock.text-yellow-600.cursor-pointer

        el-col(:span="3")
            GroupSelect.group-select(size="small")

        el-col.flex(:span="3")
            el-button.ml-auto.mr-6(type="text", icon="el-icon-upload2" @click="logoutAction") Выход

    create-transaction-form(
        :type="createType"
        :visibleSync="createType"
        @updateVisibleStatus="() => showCreateTransactionForm(false)"
    )
    create-transfer-form(
        :isVisible="transferFormShow"
        :visibleSync="transferFormShow"
        @updateVisibleStatus="() => transferFormShow = false"
    )
</template>

<script>
import { mapState } from 'vuex';

import GroupSelect from '@/components/GroupSelect';
import CreateTransactionForm from '@/components/tools/CreateTransactionForm';
import CreateTransferForm from '@/components/tools/CreateTransferForm';

export default {
    name: 'HeaderBar',
    components: { GroupSelect, CreateTransferForm, CreateTransactionForm },
    data: () => ({
        activeLink: '',
        createType: undefined,
        transferFormShow: false,
    }),
    computed: {
        ...mapState(['auth']),
        isTwoFactorEnabled() {
            return this.auth && this.auth.user.enabled2fa;
        },
    },
    watch: {
        $route(newVal) {
            this.activeLink = newVal.path;
        },
    },
    mounted() {
        this.activeLink = this.$route.path;
    },
    methods: {
        showTab(route) {
            if (!route.meta) return false;
            if (!route.meta.access) return !!route.meta.title;
            if (route.meta.access)
                return route.meta.title && this.isAllowed(route.meta.access);
            return false;
        },
        async logoutAction() {
            await this.$store.dispatch('logout');
            this.$router.push({ path: '/login' });
        },
        routeList() {
            return this.$router.options.routes.find(({ path }) => path === '/')
                .children;
        },
        showCreateTransactionForm(type) {
            this.createType = type;
        },
    },
};
</script>

<style scoped lang="scss">
.header {
    border-bottom: solid 1px #e6e6e6;

    .el-menu {
        border-bottom: 0;
    }

    .action-buttons {
        button {
            padding: auto 29px;
        }
    }
}
</style>
