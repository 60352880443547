import Vue from 'vue';
import Vuex from 'vuex';

import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import state from '@/store/state';

import accounts from './accounts';
import authenticator from './authenticator';
import relationManager from './relationManager';
import transactions from './transactions';
import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        relationManager,
        users,
        accounts,
        transactions,
        authenticator,
    },
});
