/* eslint-disable import/no-extraneous-dependencies */
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

import App from '@/App.vue';
import router from '@/router/router';
import store from '@/store/store';
import filters from '@/utils/filters';

import isAllowed from './utils/isAllowed';
import './styles.scss';

import 'tailwindcss/tailwind.css';

filters(Vue);

Vue.use(VueClipboard);
Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });

Vue.mixin({
    methods: {
        isAllowed,
    },
});

new Vue({
    router,
    render: (h) => h(App),
    store,
}).$mount('#app');
