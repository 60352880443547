<template lang="pug">
.wrapper
    h1.text-4xl.my-16.font-bold.text-center Включить Google Authenticator
    el-steps(direction="horizontal" :active="currentStep" align-center)
        el-step(v-for="(step, idx) in stepsList" :key="idx" :title="step")

    StepOne(v-if="isStep(1)" @nextStep="moveForward")
    StepTwo(v-if="isStep(2)" @previousStep="moveBack" @nextStep="moveForward")
    StepThree(v-if="isStep(3)" @previousStep="moveBack" @nextStep="moveForward")
    StepFour(v-if="isStep(4)" @previousStep="moveBack")

</template>

<script>
import { mapState } from 'vuex';

import StepFour from './StepFour';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

export default {
    name: 'SetUpAuthenticatorPage',
    components: { StepOne, StepTwo, StepThree, StepFour },

    data() {
        return {
            currentStep: 0,
            stepsList: [
                'Скачать приложение',
                'Сканировать QR-код',
                'Резервный ключ',
                'Завершено',
            ],
        };
    },
    computed: {
        ...mapState(['auth']),
    },
    beforeMount() {
        // Редиректим если аутентификатор уже привязан
        if (this.auth.user.enabled2fa) this.$router.push({ path: '/' });
    },
    methods: {
        isStep(step) {
            return this.currentStep === step - 1;
        },
        moveForward() {
            this.currentStep += 1;
        },
        moveBack() {
            this.currentStep -= 1;
        },
    },
};
</script>

<style lang="scss" scoped>
h1 {
    color: #000;
}
</style>
