/**
 * @author feod0r
 */

import store from '@/store/store';
import isAllowed from '@/utils/isAllowed';

export default async (to, from, next) => {
    if (!store.getters.isInitialLoaded) {
        store.commit('setInitialLoadRequestPending', true);
        await store.dispatch('initialLoad');
    }
    if (!store.getters.isAuthorized && to.name !== 'login') {
        return next({
            name: 'login',
        });
    }

    if (
        store.getters.isAuthorized && // Если пользователь авторизован
        to.fullPath !== '/enable-google-authenticator' && // Если мы на странице включения 2FA
        !store.state.auth.user.enabled2fa && // Если 2FA НЕ настроена
        !store.state.auth.user.doNotDemand2FA // Если требование НЕ деактивировано
    ) {
        return next({ name: 'enableGoogleAuthenticator' });
    }

    if (to.meta && to.meta.access && !isAllowed(to.meta.access)) {
        return next({
            name: 'accessDenied',
        });
    }

    return next();
};
